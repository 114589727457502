html {
    overflow-y: scroll;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
}

img {
    border-radius: 5px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    color: #505457;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    font-size: 1.2em;
    line-height: 1.5em;
    letter-spacing: 1px;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins-Regular'), url(./fonts/poppins/Poppins-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium'), url(./fonts/poppins/Poppins-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Semibold';
    src: local('Poppins-SemiBold'), url(./fonts/poppins/Poppins-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(./fonts/poppins/Poppins-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-Italic';
    src: local('Poppins-Italic'), url(./fonts/poppins/Poppins-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins-Light'), url(./fonts/poppins/Poppins-Light.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}@font-face {
    font-family: 'Poppins-ExtraLight';
    src: local('Poppins-ExtraLight'), url(./fonts/poppins/Poppins-ExtraLight.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Billbreak';
    src: local('Billbreak'), url(./fonts/Billbreak.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

h1, h2, h3 {
    letter-spacing: .05em;
}

h1.title {
    margin: 6vh;
    margin-left: 10%;
}

nav {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

a {
    text-decoration: none;
    color: #505457;
    outline: none;
}

a:hover {
    color: #a4dcf8;
    outline: none;
}
a:visited {
    color: #3a3b3e;
}

#home {
    padding-top: 3rem;
}

button:hover, button:focus {
    outline: 0 transparent;
}

a {
    text-decoration: none;
    outline: none;
}

a:hover, a:focus {
    color: #60a5fa;
}

footer a {
    font-size: 20px;
    font-weight: 600;
}

footer a:hover, footer a:focus {
    filter: brightness(130%);
}

.light-blue {
    color: #bfd9f8;
}

.blue {
    color: #60a5fa;
}

.blue-opacity {
    opacity: .8;
    color: #60a5fa;
}

.white {
    color: #fff;
}

.bmItem:hover {
    color: #60a5fa !important;

}

/*Text sections*/
.text-sections {
    max-width: 100%;
    display: block;
    padding: 4em;
}

@media screen and (max-width: 1180px) {
    .text-sections {
        padding: 1.5em;

    }
}

.writingcontainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.max970 {
    max-width: 970px;
    margin: 0 auto;
}

.max1024 {
    max-width: 1024px;
    margin: 0 auto;
}

.max1024 h1 {
    padding: .5em 0;
    margin: 50px 0 10px 0;
}

.max1024 p {
    opacity: 0.9;
    /*font-size: 1.4em;*/
    line-height: 1.5em;
    letter-spacing: .03em;
}

.left {
    text-align: left;
}

.right {
    text-align: right
}

/*Fonts*/

.cera {
    line-height: 1.8;
    font-family: Cera-GR-Light, sans-serif;
    font-size: 1em;
    letter-spacing: .2em;
    opacity: .8;
}

.goldenbook {
    line-height: 2em;
    font-family: Goldenbook, serif;
    font-size: calc(18px + 17 * (100vw - 480px) / 1440);
    font-weight: 400;
    letter-spacing: .2em;
}

h3.goldenbook {
    line-height: 1.8;
    font-size: 1.2em;
    font-weight: 400;
    font-style: italic;
    letter-spacing: .05em;
    margin-bottom: 0.5em;
}

@media screen and (min-width: 1920px) {
    h3.goldenbook {
        font-size: 35px;
    }
}

@media screen and (min-width: 1920px) {
    .goldenbook {
        font-size: 35px;
    }
}


h4.title-descrip {
    font-size: 2rem;
    font-family: GoldenbookBold, serif;
    padding: 10px 0;
    border-bottom: 1px solid rgb(191, 217, 248);
    margin: 0;
    line-height: 4rem;
    text-align: center;
}

p.sm-text {
    padding-top: 1em;
    font-size: .95em;
    font-family: Cera-GR-Light, sans-serif;
    color: rgb(191, 217, 248);
    line-height: 28.8px;
    margin-top: 5px;
    text-align: left;
}

ul.list-text {
    font-size: .95em;
    font-family: Cera-GR-Light, sans-serif;
    color: rgb(191, 217, 248);
    line-height: 28.8px;
    margin-top: 5px;
    text-align: left;
    list-style: none;
    margin-left: 3vw;
}

.center {
    position: relative;
    text-align: center;
}

.center img {
    width: 100%;
    height: auto;
}

/*Textarea and Placeholder*/

textarea {
    background: transparent;
    font-style: normal;
    color: #fff;
    opacity: .8;
    border-color: #8993ac;
    font-weight: normal;
    word-wrap: break-word;
    font-feature-settings: "kern";
    font-kerning: normal;
    letter-spacing: .03em;
    line-height: 1.4em;
    font-size: 1.3em;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    font-size: .8em;
    font-family: Poppins-ExtraLight, sans-serif;
    opacity: .9;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

.pad-top1 {
    padding-top: 1em;
}

.pad-top2 {
    padding-top: 2em;
}

.pad-top3 {
    padding-top: 3em;
}

.pad-bottom5 {
    padding-bottom: 5em;
}

textarea:focus {
    outline: none !important;
    box-shadow: 0 0 10px #e2e2e3;
}

/*main burger menu*/
.bm-item:hover {
    color: #e7ecef !important;
    transition: all 0.2s ease-in-out;
}

@media (max-width: 447px){
.bm-menu-wrap {
width: 100% !important}
}
