.img1 {
    background-image: url("img/1.jpg");
}
.img2{
    background-image: url("img/2.jpg");
}
.img3{
    background-image: url("img/3.jpg");
}
.img4{
    background-image: url("img/4.jpg");
}
.img5{
    background-image: url("img/5.jpg");
}
.img6{
    background-image: url("img/6.jpg");
}
.img7{
    background-image: url("img/7.jpg");
}
.img8{
    background-image: url("img/8.jpg");
}
.img9{
    background-image: url("img/9.jpg");
}
.img10{
    background-image: url("img/10.jpg");
}
.img11{
    background-image: url("img/11.jpg");
}
.img12{
    background-image: url("img/12.jpg");
}
.img13{
    background-image: url("img/13.jpg");
}
.img14{
    background-image: url("img/14.jpg");
}
.img15{
    background-image: url("img/15.jpg");
}
.img16{
    background-image: url("img/16.jpg");
}
.img17{
    background-image: url("img/17.jpg");
}
.img18{
    background-image: url("img/18.jpg");
}
.img19{
    background-image: url("img/19.jpg");
}
.img20{
    background-image: url("img/20.jpg");
}
.img21{
    background-image: url("img/21.jpg");
}
.img22{
    background-image: url("img/22.jpg");
}
.img23{
    background-image: url("img/23.jpg");
}
.img24 {
    background-image: url("img/24.jpg");
}
.img25{
    background-image: url("img/25.jpg");
}
.img26{
    background-image: url("img/26.jpg");
}
.img27{
    background-image: url("img/27.jpg");
}
.img28{
    background-image: url("img/28.jpg");
}
.img29{
    background-image: url("img/29.jpg");
}
.img30{
    background-image: url("img/30.jpg");
}

