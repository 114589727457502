.link-list {
  padding: 0;
  list-style-type: none;
  margin-top: 0.2em;
}

.link-list-item {
  text-align: left;
  font-size: 1em;
  padding: .05em 0;
}


.link-list-item-url {
  text-decoration: none;
  margin: 6px;
  display: block;
  color: #A2ADB3;
  background-color: transparent;
  padding: 8px;
  border-radius: 3px;
  border: solid 1px rgba(215, 224, 229, 0.4);
  font-family: Poppins-Light, sans-serif;
  line-height: 25px;
  min-width: 80%;
  font-size: 1em;
  letter-spacing: .03em;
}