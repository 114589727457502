.txtoutline {
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    margin-bottom: .8em;
    font-size: .85em;
}

.outline1024 {
    max-width: 1024px;
    margin: 0 auto;
    font-size: 1.2em;
}

.outline1024 p {
    opacity: 0.9;
    line-height: 1.8em;
    letter-spacing: .03em;
}

@media (max-width: 1080px) {
    .outline1024 {
        padding: 0 1.5em;
    }
}

.outline1024 h1 {
    padding: .5em 0;
    margin: 50px 0 10px 0;
    font-size: 1.4em;
}

.map-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('map.jpg');
    background-size: cover;
    background-position: center;

}

p.about-title {
    color: #505457;
    font-size: 2em;
    font-weight: bold;
    padding-top: 1.5em;
}