/*Custom Config for chatbot*/
div.react-chatbot-kit-chat-container {
    max-width: 100%;
}

.react-chatbot-kit-chat-inner-container {
    position: fixed;
    border: transparent;
    bottom: 85px;
    right: 25px;
    z-index: 9;
    background-color: #fff;
    max-width: 420px;
    border-radius: 10px !important;
    /*If glitchy black screen at size 600px, move scroll-y here*/
    /*overflow-y: scroll;*/
    /*max-height: 550px;*/

}

.react-chatbot-kit-chat-message-container {
    background-color: #fff !important;
    padding: 20px;
    /*If glitchy black screen at size 600px, move scroll-y above*/
    overflow-y: scroll;
    max-height: 450px;
    border-radius: 10px !important;
    border: 1px solid #e5e8e8;
}

.react-chatbot-kit-chat-header {
    display: none;
}

.react-chatbot-kit-chat-input-container {
}

.react-chatbot-kit-chat-input {
    background-color: #a3b2bd !important;
    color: #fff !important;
    padding: 6px 15px;
    width: 80%;
    font-size: 1em;
    border: 1px solid #e5e8e8;
    border-radius: 25px;
}

.react-chatbot-kit-chat-input:focus {
    box-shadow: 0 0 10px #a3b2bd;
    outline: none !important;
}

.react-chatbot-kit-chat-input-form {
    background-color: #a3b2bd !important;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.react-chatbot-kit-chat-input::placeholder {
    color: #fff !important;
}

.react-chatbot-kit-chat-btn-send {
    background-color: #a3b2bd !important;
    border-color: transparent;
    color: #fff;
    width: 35px;

}

.react-chatbot-kit-chat-btn-send-icon {
    fill: #fff;
}

.react-chatbot-kit-chat-bot-message-container {
}

.react-chatbot-kit-chat-bot-avatar-container {
    display: none;
}

.react-chatbot-kit-chat-bot-avatar-letter {
    display: none;
}

.react-chatbot-kit-user-avatar-container {
    display: none;
}

.react-chatbot-kit-chat-bot-avatar-icon {
    display: none;
}

.react-chatbot-kit-chat-bot-message {
    background-color: #C2CDD5 !important;
    color: #fff;
    line-height: 25px;
    min-width: 80%;
    font-size: 1em;
    letter-spacing: .03em;
    border-radius: 1em;
    padding: 0.5em 0.8em;
    margin: .4em 0.0625em;
    max-width: 80%;
    border-bottom-left-radius: 0.1em;

}

.react-chatbot-kit-user-chat-message-container {
    display: flex;
    justify-content: right;
}

.react-chatbot-kit-user-chat-message {
    background-color: #fff !important;
    color: #A2ADB3;
    line-height: 24px;
    font-size: 1em;
    letter-spacing: .035em;
    border-radius: 1em;
    padding: 0.5em 0.8em;
    margin: .4em 0.0625em;
    max-width: 80%;
    min-width: 20%;
    border-bottom-right-radius: 0.1em;
}

.react-chatbot-kit-user-chat-message-arrow {
    display: none;
}

div.react-chatbot-kit-chat-bot-message-arrow {
    display: none;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}

svg.popup-arrow {
    display: none;
}

/*Scrollbar*/
/*width*/
/*.react-chatbot-kit-chat-message-container::-webkit-scrollbar {*/
/*    width: 10px;*/
/*    height: 100%;*/
/*}*/
/* Track */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-track {
    background: #A2ADB3;
}
/* Handle */
.react-chatbot-kit-chat-message-container::-webkit-scrollbar-thumb {
    background: #A2ADB3;
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}


/*robot */
/*Tiny robot icon position -bottom right most pages*/
.robot-icon-position {
    position: fixed;
    z-index: 50;
    bottom: -75px;
    right: -75px;
}

/*Tiny icon Scale*/
/*Scale down size - to use most pages bottom right*/
.robot-scale-icon {
    -ms-transform: scale(0.17);
    transform: scale(0.17);
}

/*Homepage Scale*/
.robot-scale-home {
    /*Scale down size - to use on Home page*/
    -ms-transform: scale(0.4);
    transform: scale(0.4);
}

/*Tiny icon no gradient*/
.robot-radial-gradient-none {
}

/*Home page*/
.robot-radial-gradient {
    background: radial-gradient(#011e48, #011f49, #02091c, #02071b);
    flex-basis: 100%;
}

/*Tiny icon no robot message*/
.robot-text-none {
    display: none;
}

/*Home page robot message*/
.robot-text {
    flex-basis: 100%; /* or - flex: 0 50% - or - flex-basis: 50% - */
    display: flex;
    flex-direction: column;
    text-align: left;
}
@media (min-width: 500px) {
    .robot-text {
        text-align: justify;
    }
}

.robot-text p {
    color: rgb(243, 248, 253);
    line-height: 1.8em;
    font-size: 1.3em;
}

@media (min-width: 769px) {
    .robot-text p {
        line-height: 1.6em;
        font-size: 1.1em;
    }
}

@media screen and (min-width: 850px) {
    .robot-home-position {
        padding: 5em .5em;
    }

    .robot-text {
        flex-basis: 55%;
    }

    .robot-radial-gradient {
        flex-basis: 45%;
    }
}


.robot {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.robot * {
    box-sizing: border-box;
}

.robot .circle-bg {
    background: transparent;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    position: relative;
}

.robot .circle-bg:after {
    content: "";
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: -3px;
    left: 0;
    border-radius: 100%;
}

.robot .circle-bg:hover .robot-face {
    top: 40px !important;
}

.robot .circle-bg:hover .eyes {
    top: 30px !important;
}

.robot .circle-bg:hover .mouth {
    background: #45464B;
    border: none;
    clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%);
}

.robot .circle-bg:hover .mouth:after {
    content: "";
    position: absolute;
    height: 80%;
    width: 80%;
    bottom: -30%;
    left: 50%;
    transform: translateX(-50%);
    background: #f9bbbb;
    border-radius: 100%;
}

.robot .circle-bg .robot-head {
    height: 190px;
    width: 200px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    border: 8px solid #45464B;
    border-radius: 85px/60px;
    background: #ffffff;
    z-index: 4;
}

.robot .circle-bg .robot-head:hover {
    background: #cae0e0;
}

.robot .circle-bg .robot-head:after {
    content: "";
    position: absolute;
    top: -30px;
    height: 30px;
    width: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
}

.robot .circle-bg .robot-head:before {
    content: "";
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    height: 25px;
    width: 25px;

    box-shadow: 0 0 25px 25px #cae0e0;
    background: #95d0d0;
    border: 8px solid #95d0d0;
    border-radius: 100%;
}

.robot .circle-bg .robot-head .robot-face {
    height: 120px;
    width: 160px;
    background: #d7e0e5;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border: 8px solid #45464B;
    transition: all 200ms;
    border-radius: 50px;
}

.robot .circle-bg .robot-head .robot-face .eyes {
    height: 25px;
    width: 25px;
    background: #1c233b;
    border-radius: 100%;
    position: absolute;
    transition: all 200ms;
    top: 40px;
}

.robot .circle-bg .robot-head .robot-face .eyes.left {
    left: 25px;
    animation: blink 3s 3s infinite;
}

.robot .circle-bg .robot-head .robot-face .eyes.right {
    right: 25px;
    animation: blink 3s 3s infinite;
}

@keyframes blink {
    0%, 100% {
        transform: scale(1, .05);
    }
    5%,
    95% {
        transform: scale(1, 1);
    }
}

.robot .circle-bg .robot-head .robot-face .mouth {
    height: 45px;
    width: 45px;
    border-radius: 100%;
    border: 8px solid transparent;
    border-bottom-color: #45464B;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    overflow: hidden;
}

.robot .circle-bg .robot-ear {
    position: absolute;
    height: 90px;
    width: 110px;
    border-radius: 100%;
    background: #ffffff;
    border: 8px solid #45464B;
    z-index: 3;
    top: 38px;
}

.robot .circle-bg .robot-ear.left {
    left: -10px;
}

.robot .circle-bg .robot-ear.right {
    right: -10px;
}

.robot .circle-bg .robot-body {
    height: 75px;
    width: 127px;
    border: 8px solid #45464B;
    background: #ffffff;
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px 100px 230px 230px;
}

[role=tooltip].popup-content {
    background-color: transparent;
    border: none;
}

