.AI-container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 1em 1em 5em;
    opacity: .50;
    transform: translateY(50px);
    animation: fadeInUp 1.5s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.AI-btn {
    font-family: Poppins-Light, sans-serif;
    opacity: .9;
    background: transparent;
    font-size: 1.2em;
    margin-top: 2em;
    padding: .7rem 1rem;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    letter-spacing: .06em;

}

.AI-btn:hover {
    color: #CDDBE8;
    border: 1px solid #fff;
    box-shadow: 0 0 10px #fff;
    background: rgba(255, 254, 254, 0.9);
}

.AI-btn-discover{
    font-family: Poppins-Light, sans-serif;
    opacity: .9;
    background: transparent;
    font-size: 1.2em;
    margin-top: 2em;
    padding: .7rem 1rem;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    letter-spacing: .06em;

}

.AI-btn-discover:hover {
    color: rgb(123, 113, 122);
    border: 1px solid #fff;
    box-shadow: 0 0 10px #fff;
    background: rgba(255, 254, 254, 0.9);

}
.AI-btn-discover:focus {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
    background: transparent !important;
}

.AI-input {
    width: 99%;
    color: #fff;
    margin: 10px 0;
    font-family: Poppins, sans-serif;
    padding: 1.5em;
    height: 3.8em;
    background: transparent;
    line-height: 1.375em;
    font-style: normal;
    box-shadow: none;
    border: solid 1px #fff;
    font-size: 1.1em;
    border-radius: 10px;
}

.AI-input::placeholder {
    line-height: 1.6em;
    color: #fff;
    font-family: 'Poppins-Light', sans-serif;
    text-align: left;
    font-size: 1.1em;

}
.AI-input-white::placeholder {
    line-height: 1.6em;
    color: #fff;
    font-family: 'Poppins-Light', sans-serif;
    text-align: left;
    font-size: 1.1em;
}

.dark-placeholder {
    width: 99%;
    color: #6F7275;
    margin: 10px;
    font-family: Poppins, sans-serif;
    padding: 1.5em;
    height: 3.8em;
    background: transparent;
    line-height: 1.375em;
    font-style: normal;
    box-shadow: none;
    border: solid 1px #6f7275;
    font-size: 1.1em;
    border-radius: 10px;
}

.dark-placeholder::placeholder {
    color: #6F7275;
}

.AI-input:focus {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
    background: transparent !important;
}
.AI-input-white:focus {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
    background: transparent !important;
}

.AI-title {
    padding: 1em 0;
    font-size: 2em;
    text-align: center;
    line-height: normal;
}

.AI-summary {
    font-family: Poppins-Light, sans-serif;
    margin: 2em 0;
    color: #fff;
    font-size: 1.2em;
    line-height: 1.4em;
    padding: 1em;
    border: 1px solid #fff;
    border-radius: 10px;
}

.AI-grammar {
    margin: 2em 0;
    font-size: 1.1em;
    line-height: 1.2em;
    padding: 1em;
    border: 1px solid #fff;
    border-radius: 10px;
}

.AI-followup {
    text-align: center;
    color: #fff;
    font-size: 1.1em !important;
}


.AI-intro {
    margin-top: 2em;
    text-align: center;
    color: #fff;
    font-size: 1.1em !important;
}

.AI-page-title {
    text-align: center;
    padding: 1em 1em;
    animation-delay: .5s;
    font-family: Poppins-Medium, sans-serif;
    font-size: 3.5em;
    font-weight: 500;
    color: #fff;
    opacity: 1 !important;
    line-height: 1.1em;
}

.AI-cat-descriptions {
    font-size: 1.4em;
    line-height: 1.6em;
    color: #fff;
    padding: 1em 0 1.5em;
    font-family: 'Poppins-Light', sans-serif;
    text-align: left;
}

.AI-questions {
    font-size: 1.1em;
    line-height: 1.6em;
    color: #fff;
    font-family: 'Poppins-Light', sans-serif;
    text-align: left;
    padding-top: 1.2em;
}

.dark {
    color: #505457;
    opacity: 1;
}
.discover {
    color: #7B717A;
    opacity: 1 !important;
}

.discover-background-image {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url('img/discover.jpg');
    background-size: cover;
    background-position: center;
    animation: fadeOutImage 2s forwards;
}

.costs-background-image {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url('img/costs.jpg');
    background-size: cover;
    background-position: center;
    animation: fadeOutImage 2s forwards;
}

.visas-background-image {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url('img/visas.jpg');
    background-size: cover;
    background-position: center;
    animation: fadeOutImage 2s forwards;
}
.fitness-background-image {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url('img/fitness.jpg');
    background-size: cover;
    background-position: center;
    animation: fadeOutImage 2s forwards;
}
.community-background-image {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url('img/community.jpg');
    background-size: cover;
    background-position: center;
    animation: fadeOutImage 2s forwards;
}

@keyframes fadeOutImage {
    from {
        opacity: .3;
    }
    to {
        opacity: 0;
    }
}