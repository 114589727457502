/*Hero Section*/
.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    object-fit: contain;
    margin-top: -80px;
    position: relative;
}


.image-line1,
.image-line2 {
    position: absolute; /* Position images absolutely within the container */
    opacity: 0; /* Initially hide the images */
    transition: opacity 1s ease-in-out; /* Set fade-in animation */
}

.image-line1 {
    width: 60vw;
    top: 37%;
    transform: translateY(-50%);
    animation: fade-in 2s ease-in-out forwards;
    animation-delay: .5s;
}

.image-line2 {
    animation: fade-in 2s ease-in-out forwards;
    width: 60vw;
    top: 50%;
    padding-top: 5em;
    transform: translateY(-50%);
    animation-delay: 2.5s;
}

@media screen and (max-width: 875px) {
    .image-line1  {
        width: 95vw;
        top: 41%;
    }
    .image-line2  {
        top: 47%;
        width: 95vw;
        padding-top: 4em;

    }
}
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}


@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 60px;
    }
}

@media (min-width: 480px) {
    .writing-links p {
        font-size: calc(16px + 17 * (100vw - 480px) / 1440);
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (min-width: 480px) {
    .writing-links p {
        font-size: calc(16px + 17 * (100vw - 480px) / 1440);
    }
}

.tagline {
    line-height: 2em;
    color: #a8caca;
    font-size: 2.5em !important;
    font-family: 'Poppins-Semibold', sans-serif;
    text-align: center;
    padding: 3em 0;
}

@media screen and (max-width: 530px) {
    .tagline {
        font-size: 1.8em !important;
        text-align: center;
        padding: 1em 0;
    }
}

.writing-links {
    font-family: Poppins, serif;
    font-size: 18px;
    color: #a8caca;
}

/*Flex elements*/
.flex-container {
    display: flex;
    justify-content: center;
}

.flex-container > div {
    margin: 10px;
    padding: 20px;
}

/*Cross-fade multiple images for hero*/
.pic {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-animation: slide 12s infinite;
    animation: slide 12s infinite;
    opacity: 0;
}

@media (orientation: landscape) {
    #pic1 {
        background-image: url(./Home/hero-images/paris.avif) ;
        animation-delay: 0s;
    }
}

@media (orientation: portrait) {
    #pic1 {
        background-image: url(./Home/hero-images/paris-sm.avif);
        animation-delay: 0s;
    }
}

@media (orientation: landscape) {
    #pic2 {
        background-image: url(./Home/hero-images/penguins.avif);
        animation-delay: 3s;
    }
}

@media (orientation: portrait) {
    #pic2 {
        background-image: url(./Home/hero-images/penguins-sm.avif);
        animation-delay: 3s;
    }
}

@media (orientation: landscape) {
    #pic3 {
        background-image: url(./Home/hero-images/indonesia.avif);
        animation-delay: 6s;
    }
}

@media (orientation: portrait) {
    #pic3 {
        background-image: url(./Home/hero-images/indonesia-sm.avif);
        animation-delay: 6s;
    }
}

@media (orientation: landscape) {
    #pic4 {
        background-image: url(./Home/hero-images/safari.avif);
        animation-delay: 9s;
    }
}

@media (orientation: portrait) {
    #pic4 {
        background-image: url(./Home/hero-images/safari-sm.avif);
        animation-delay: 9s;
    }
}

@keyframes slide {
    10% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    50% {
        transform: scale(1.1);
    }
}

@-webkit-keyframes slide {
    10% {
        opacity: 1;
    }
    25% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    50% {
        transform: scale(1.1);
    }
}


/*bottom information - three items across*/
img.home-icon {
    width: 94%;
    padding-top: 7em;
    font-size: .95em;
    color: #a3cbcb;
    line-height: 28.8px;
    margin-top: 5px;
    text-align: left;
    height: auto;
    vertical-align: bottom;
}

@media screen and (max-width: 850px) {
    .three-to-one {
        display: block;
        margin: auto 0;
        height: auto;
    }

    img.home-icon {
        width: 100%;
    }

    div.home-padding {
        padding-bottom: 7em;
    }
}

@media screen and (max-width: 520px) {
    div.home-padding {
        padding-bottom: 0;
    }
}


@media screen and (min-width: 850px) {
    .three-to-one {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    div.home-rt {
        padding-right: 1em;
    }
}

h2.home-text {
    font-size: .95em;
    color: #505457;
    line-height: 28.8px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    margin-top: -10.5em;
    padding: 0 1em;
}

@media screen and (max-width: 850px) {
    h2.home-text {
        padding: 1em 1em;
        font-size: 2.4em;
    }
}

@media screen and (max-width: 520px) {
    h2.home-text {
        padding: 2em 1em 0;
        font-size: 1.5em;
    }
}

p.home-text {
    font-size: .95em;
    color: #505457;
    line-height: 28.8px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 0 1em;
}

@media (max-width: 850px) {
    p.home-text {
        font-size: 2em;
        padding: 0 1.5em;
        line-height: 1.5em;
    }
}

@media screen and (max-width: 520px) {
    p.home-text {
        padding: 0 1em;
        font-size: 1.2em;
    }
}

/*Hero Section*/
.hero-container {
    height: 114vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin-top: -80px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 60px;
    }
}

.writing-links {
    font-family: GoldenbookLight, serif;
    font-size: 18px;
    color: #c9e1f8;
}

/*Flex elements*/
.flex-container {
    display: flex;
    justify-content: center;
}

.flex-container > div {
    margin: 10px;
    padding: 20px;
}

.api-key-input {
    width: 65%;
    margin-right: 10px;
    padding: 1em;
    height: 2em;
    background: transparent;
    line-height: 1.375em;
    font-style: normal;
    color: #fff;
    border: solid 1px #8993ac;
    font-size: 1.2em;
    font-family: GoldenbookLight, serif;
    border-radius: 10px;
    outline: none !important;
    box-shadow: 0 0 10px #a3cbcb;
}

.api-key-input::placeholder {
    font-size: 1.2em;
    color: #fff;
    font-family: Poppins-ExtraLight, sans-serif;
}

.api-key-input:hover {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
    background: transparent !important;
}

.api-key-btn {
    margin-left: 0;
    width: 25%;
    height: 2em;
    background: transparent;
    line-height: 1.375em;
    font-style: normal;
    color: #a3cbcb;
    border: solid 1px #8993ac;
    font-size: 1.3em;
    font-family: GoldenbookLight, serif;
    border-radius: 10px;
    outline: none !important;
    box-shadow: 0 0 10px #afcbcb;
}

.api-key-btn:hover {
    outline: none !important;
    box-shadow: 0 0 10px #fff;
    background: transparent !important;
}

@media (min-width: 600px) {
    .api-key-btn {
        margin-left: 15px;
        width: 15%;
    }
}

@media (min-width: 1200px) {
    .api-key-input {
        margin-left: 15px;
        width: 80%;
    }
}

/*Read More/less*/
.mb-12 {
    margin-bottom: 3rem;
}

.read-more {
    --line-clamp: 1;
}

.read-more__text {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.read-more__checkbox {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.read-more__checkbox ~ .read-more__label {
    cursor: pointer;
    font-size: 0.75em;
    font-weight: 700;
    /*text-decoration: underline;*/
}

.read-more__checkbox:focus ~ .read-more__label {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

.read-more__checkbox:hover ~ .read-more__label {
    text-decoration: none;
}

.read-more__checkbox ~ .read-more__label::before {
    content: attr(data-read-more);
}

.read-more__checkbox:checked ~ .read-more__label::before {
    content: attr(data-read-less);
}

.read-more__checkbox:checked ~ .read-more__text {
    --line-clamp: none;
    -webkit-line-clamp: var(--line-clamp);
}
